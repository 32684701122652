// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

// mixins
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

export const drawerSettingsMixin = {
  mixins: [publicAccessMixin],
  computed: {
    ...mapGetters({
      usesSidebarImage: "usesSidebarImage",
      enableMiniDrawer: "enableMiniDrawer"
    }),

    /**
     * drawer Setting Enable Mini Sidebar
     * @return {boolean|boolean} if true Enable drawer Mini Sidebar
     */
    enabledMiniSidebar() {
      return this.isPublicAccess
        ? this.publicAccess?.sideBar?.enableMiniSidebar ?? true
        : this.enableMiniDrawer;
    },

    /**
     * sidebar background Image Url
     * @return {string|undefined} sidebar background Image Url
     */
    sidebarImageUrl() {
      return this.isPublicAccess
        ? this.publicAccess?.sideBar?.backgroundImageUrl ?? undefined
        : this.usesSidebarImage
        ? require("@/assets/icons/bg-10.jpg")
        : undefined;
    }
  }
};
