<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    v-bind="$attrs"
    v-on="$listeners"
    :clipped="drawerClipped"
    :right="right"
    :mini-variant.sync="drawerMiniVariant"
    :dark="drawerDark"
    :src="sidebarImageUrl"
    :class="drawerClass"
    :expand-on-hover="expandOnHover"
    width="350"
  >
    <template v-slot:prepend>
      <slot name="prepend">
        <!-- Toggle Mini Drawer (provided as default) -->
        <v-list-item v-show="enabledMiniSidebar">
          <v-list-item-icon>
            <base-tooltip-icon @click="$emit('toggleMiniDrawer')">
              {{ computeDrawerShrinkIcon }}
              <template v-slot:toolTip>
                {{ computeDrawerShrinkTooltip }}
              </template>
            </base-tooltip-icon>
          </v-list-item-icon>
        </v-list-item>
      </slot>
    </template>

    <!-- slot divider -->
    <v-divider></v-divider>

    <!-- slot alert -->
    <slot name="alert">
      <v-list-item v-if="error">
        <v-list-item-content>
          <base-alert v-model="showAlert" :dismissible="dismissibleAlert">{{
            error
          }}</base-alert>
        </v-list-item-content>
      </v-list-item>
    </slot>

    <!-- slot content -->
    <slot name="content"></slot>

    <!-- slot divider -->
    <template v-if="hasSlot('append')">
      <v-divider></v-divider>
    </template>

    <!-- slot append -->
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
// design
import { iconArrowLeftDrop, iconArrowRightDrop } from "@/design/icon/iconConst";

// utils
import { isEmpty } from "@/utils";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapGetters } = createNamespacedHelpers("solution");

// store
import { SET_DRAWER } from "@/store/solution/mutation-types";

// mixins
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { alertableErrorMixin } from "@/mixins/shared/alertable/alertableErrorMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";
import { drawerSettingsMixin } from "@/mixins/shared/base/settings/drawerSettingsMixin";

export default {
  name: "CoreNavigationDrawer",
  inheritAttrs: false,
  mixins: [
    reactiveMixin,
    alertableErrorMixin,
    slottableMixin,
    drawerSettingsMixin
  ],
  components: {
    BaseTooltipIcon: () =>
      import("@/components/shared/base/icon/BaseTooltipIcon")
  },
  data() {
    return {
      drawerMiniVariant: false,
      expandOnHover: false
    };
  },
  props: {
    right: {
      type: Boolean,
      default: false
    },
    drawerClass: {
      default: undefined
    },
    drawerMini: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      visibleLeftDrawer: "visibleLeftDrawer",
      usesSidebarImage: "usesSidebarImage"
    }),

    /**
     * Get/Set Drawer visibility
     */
    drawer: {
      get() {
        return this.visibleLeftDrawer;
      },
      set(value) {
        this.setDrawer(value);
      }
    },

    /**
     * Get is clipped drawer
     * A clipped drawer rests under the appModule toolbar.
     * Note: requires the clipped-left or clipped-right prop on v-app-bar to work as intended
     * @returns {(function(): boolean)|*}
     */
    drawerClipped() {
      return this.visibleOnMdAndUp;
    },

    /**
     * Is Drawer Dark
     * @returns {boolean}
     */
    drawerDark() {
      return !isEmpty(this.sidebarImageUrl);
    },

    /**
     * Computed Drawer Shrink Icon name
     * @return {string}
     */
    computeDrawerShrinkIcon() {
      return this.right
        ? this.drawerMiniVariant
          ? iconArrowLeftDrop
          : iconArrowRightDrop
        : this.drawerMiniVariant
        ? iconArrowRightDrop
        : iconArrowLeftDrop;
    },

    /**
     * computed Drawer Shrink Tooltip
     * @return {string}
     */
    computeDrawerShrinkTooltip() {
      return this.drawerMiniVariant ? "Expand Sidebar" : "Shrink Sidebar";
    }
  },
  methods: {
    ...mapMutations({ setDrawer: SET_DRAWER })
  },
  watch: {
    drawerMini(newValue) {
      this.drawerMiniVariant = newValue;
    }
  }
};
</script>
